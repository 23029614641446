

//Screen Size
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;



/*max*/
.m {
  &w-100 {
    max-width: 100% !important;
  }

  &h-100 {
    max-height: 100% !important;
  }

  &vw-100 {
    max-width: 100vw !important;
  }

  &vh-100 {
    max-height: 100vh !important;
  }
}

/*min*/
.min- {
  &w-100 {
    min-width: 100% !important;
  }

  &h-100 {
    min-height: 100% !important;
  }

  &vw-100 {
    min-width: 100vw !important;
  }

  &vh-100 {
    min-height: 100vh !important;
  }

  &w-auto {
    min-width: auto !important;
  }
}

/*width*/
.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.overflow- {
  &x-auto {
    overflow-x: auto !important;
    overflow-y: hidden;
  }

  &y-auto {
    overflow-x: hidden;
    overflow-y: auto !important;
  }
}

.sticky- {
  &top {
    position: sticky !important;
    top: 0;
    z-index: 1020;
  }

  &bottom {
    position: sticky !important;
    bottom: 0;
    z-index: 1020;
  }

  &left {
    position: sticky !important;
    left: 0;
    z-index: 1020;
  }

  &right {
    position: sticky !important;
    right: 0;
    z-index: 1020;
  }
}


@for $size from 1 to 101 {
  .w-#{$size}px {
    width: #{$size}px !important;
  }

  .w-#{$size} {
    width: #{$size + '%'} !important;
  }
  .max-w-#{$size} {
    max-width: #{$size + '%'} !important;
  }
  .min-w-#{$size} {
    min-width: #{$size + '%'} !important;
  }
  .max-w-#{$size}px {
    max-width: #{$size + 'px'} !important;
  }
  .min-w-#{$size}px {
    min-width: #{$size + 'px'} !important;
  }
  .max-vw-#{$size}px {
    max-width: #{$size + 'vw'} !important;
  }

}

/*HEIGHT*/

@for $size from 1 to 101 {
  .h-#{$size}px {
    height: #{$size}px !important;
  }
  .vh-#{$size} {
    height: #{$size}vh !important;
  }
  .min-h-#{$size}px {
    min-height: #{$size}px !important;
  }
  .max-h-#{$size} {
    max-height: #{$size + '%'} !important;
  }
  .min-h-#{$size} {
    min-height: #{$size + '%'} !important;
  }
  .line-height-#{$size}px {
    line-height: #{$size}px !important;
  }
  .max-h-#{$size}vh {
    max-height: #{$size + 'vh'} !important;
  }
}

@media (max-width: $sm) {
  @for $size from 1 to 101 {
    .w-xs-#{$size} {
      width:  #{$size + '%'} !important;
    }
  }
  .w-xs-auto {
    width: auto !important;
  }

  //display xs media
  .d-xs-none {
    display: none !important;
  }
  .d-xs-block {
    display: block !important;
  }
  .d-xs-flex {
    display: flex !important;
  }
}



@media (max-width: $md) {
  @for $size from 1 to 101 {
    .w-md-#{$size} { /* Use w-md instead of w-xs */
      width:  #{$size + '%'} !important;
    }
  }
  .w-md-auto { /* Use w-md-auto instead of w-xs-auto */
    width: auto !important;
  }
}

@media (min-width: $md) and (max-width: $lg) {
  @for $size from 1 to 101 {
    .w-md-#{$size}, .w-sm-#{$size} { /* Use w-md instead of w-xs */
      width:  #{$size + '%'} !important;
    }
  }
  .w-md-auto, .w-sm-auto { /* Use w-md-auto instead of w-xs-auto */
    width: auto !important;
  }
}

@media (min-width: $lg) and (max-width: $xl) {
  @for $size from 1 to 101 {
    .w-md-#{$size}, .w-sm-#{$size}, .w-md-#{$size} {
      width:  #{$size + '%'} !important;
    }
  }
  .w-md-auto, .w-sm-auto, .w-md-auto {
    width: auto !important;
  }
}

@media (min-width: $xl) {
  @for $size from 1 to 101 {
    .w-md-#{$size}, .w-sm-#{$size}, .w-md-#{$size}, .w-lg-#{$size} {
      width:  #{$size + '%'} !important;
    }
  }

  .w-md-auto, .w-sm-auto, .w-md-auto, .w-lg-auto {
    width: auto !important;
  }
}


// @media (max-width: $md) {
//   @for $size from 1 to 101 {
//     .w-xs-#{$size} {
//       width: #{$size + '%'} !important;
//     }
//   }
//   .w-xs-auto {
//     width: auto !important;
//   }
// }

// @media (min-width: $md) and (max-width: $lg) {
//   @for $size from 1 to 101 {
//     .w-xs-#{$size}, .w-sm-#{$size} {
//       width: #{$size + '%'} !important;
//     }
//   }
//   .w-xs-auto, .w-sm-auto {
//     width: auto !important;
//   }

// }

// @media (min-width: $lg) and (max-width: $xl) {
//   @for $size from 1 to 101 {
//     .w-xs-#{$size}, .w-sm-#{$size}, .w-md-#{$size} {
//       width: #{$size + '%'} !important;
//     }
//   }
//   .w-xs-auto, .w-sm-auto, .w-md-auto {
//     width: auto !important;
//   }
// }

// @media (min-width: $xl) {
//   @for $size from 1 to 101 {
//     .w-xs-#{$size}, .w-sm-#{$size}, .w-md-#{$size}, .w-lg-#{$size} {
//       width: #{$size + '%'} !important;
//     }
//   }

//   .w-xs-auto, .w-sm-auto, .w-md-auto, .w-lg-auto {
//     width: auto !important;
//   }
// }

