@use 'color';

%button {
  width: fit-content;
  padding: 16px 28px;
  transition: background-color 0.3s ease;
  flex-wrap: nowrap;
  white-space: nowrap;
}

.button-primary {
  margin: auto;
  @extend %button;
  color: #fff !important;
  border: 1px solid $color_primary--600;
  background: $color_primary--600;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  font-weight: 500 !important;
}
