@import 'assets/scss';

$color_primary--300: #bbc8d2;
$color_primary--500: #385f7d;
$color_primary--600: #0c3c60;
$color_primary--700: #082a43;
$color_primary--800: #07253c;
$color_primary--900: #051826;

body {
  font-family: Poppins !important;
  font-size: 16px;
}

p {
  margin: 0;
  padding: 0;
}

button {
  font-family: Poppins;
}

.cdk-global-scrollblock {
  position: static !important;
  width: initial !important;
  overflow-y: inherit !important;
}
