@import '../mics/mixins';
@import 'color';
.footer-container {
  padding: 64px 0 24px 0;
  background-color: $color_primary--600;

  .footer-header {
    font-size: 34px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 1.7px;
  }

  .contactUs-form {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .form-container {
      display: flex;
      align-items: flex-start;
      gap: 32px;
      flex-wrap: wrap;

      .form-input {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 6px;
        align-self: stretch;
        width: 100%;
        flex: 1;

        .input-label {
          color: var(--base-white, #fff);
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          //white-space: nowrap;
        }

        .input-text_wraper {
          display: flex;
          padding: 8px 12px;
          border-bottom: 1px solid var(--gray-300, #d0d5dd);
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          width: 100%;
          min-height: 46.5px;
          .input-text {
            width: 100%;
            border: none;
            background-color: transparent;
            font-weight: 400;
            line-height: 170%;
            color: var(--gray-500, #667085);

            &::placeholder {
              color: var(--gray-500, #667085);
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 170%;
            }

            &:focus {
              outline: none;
            }
          }

          input[type='text'] {
            color: var(--base-white, var(--colors-base-white, #fff));
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 170%;
          }
        }

        .select-text {
          width: 100%;
          border: none;
          background-color: transparent;
          font-weight: 400;
          line-height: 170%;
          color: #fff;

          .select-outgroup {
            background-color: #fff;
            color: var(--gray-500, #667085);
            font-size: 18px;
          }

          option {
            background-color: var(--gray-500, #667085);
            color: #fff;
            font-size: 14px;
          }
        }

        .select-text--placeholder {
          color: var(--gray-500, #667085);
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 170%;
        }

        .textarea-text {
          width: 100%;
          border: none;
          outline: none;
          background-color: transparent;
          font-weight: 400;
          line-height: 170%;
          color: #fff;

          &::placeholder {
            color: var(--gray-500, #667085);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 170%;
          }
        }

        .error-text {
          color: var(--error-400, #f97066);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 170%;
        }
      }
    }

    .form-submit_btn {
      display: flex;
      justify-content: center;
      gap: 12px;
      border: 1px solid var(--gray-300, #d0d5dd);
      background: var(--base-white, #fff);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      padding: 12px 20px;
      margin: 0 auto;
      width: 65%;

      &:disabled {
        opacity: 0.7;
        cursor: default;
      }

      .request-loading_icon {
        animation: loading 700ms linear infinite;

        @keyframes loading {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }
      }
    }
  }
}

::ng-deep .request-livedemo-modal {
  .ant-modal-body {
    padding: 0px;
  }

  .ant-modal-close-x {
    color: #fff;
  }

  .header {
    display: flex;
    justify-content: center;
  }

  .content-wrapper {
    padding: var(--spacing-4xl, 32px) var(--spacing-3xl, 24px);
  }

  .popup-title {
    color: $color_primary--600;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px; /* 150% */
  }
}

::ng-deep {
  .ant-modal.modal-form {
    width: 80% !important;
    .ant-modal-content {
      overflow-y: scroll;
      max-height: 80vh;
    }
  }
  .ant-modal-body {
    padding: 0;
  }
}

.header {
  width: 100%;
  padding: 8px var(--spacing-3xl, 24px);
  background-color: $color_primary--600;
}

.hide-icon-select {
  -webkit-appearance: none;
}

@include mobile {
  .google-iframe {
    width: 100% !important;
  }

  .contactUs-form {
    width: 100% !important;
  }

  .form-input {
    flex: none !important;
  }
}
