body {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

button {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

a {
  text-decoration: unset;
}

p {
  margin: 0;
}
ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
