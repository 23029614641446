$mobile-width: 480px;
$tablet-width: 481px;
$bigtablet-width: 768px;
$desktop-width: 1024px;
$largedesktop-width: 1920px;
$xlargedesktop-width: 2560px;

@mixin mobile {
  @media (max-width: #{$mobile-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin bigtablet {
  @media (min-width: #{$bigtablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin large-desktop {
  @media (min-width: #{$largedesktop-width}) {
    @content;
  }
}

@mixin xlarge-desktop {
  @media (min-width: #{$xlargedesktop-width}) {
    @content;
  }
}
